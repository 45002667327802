import axios from 'axios'
import React from 'react'
import { useAuth } from '../hooks/useAuth'

class ApiError extends Error {
  constructor(originalError) {
    super(originalError.message)
    this.errors = originalError?.response?.data?.errors
    this.name = 'ApiError'
  }
}

const useApiCall = ({ method = 'get', path, data: body }) => {
  const { tokens } = useAuth()

  const headers = {}

  if (tokens?.access) {
    headers['x-access-token'] = tokens.access
  }

  // throws error
  const apiCall = async (data, runtimePath) => {
    try {
      const response = await axios({
        method,
        baseURL: '/api/v1',
        url: runtimePath || path,
        data: data || body,
        headers,
      })
      return response.data.data
    } catch (error) {
      console.log('errrrrrrrr', error.response.data.errors)
      throw new ApiError(error)
    }
  }

  return [apiCall]
}

export default useApiCall
