import Box from '@kiwicom/orbit-components/lib/Box'
import Heading from '@kiwicom/orbit-components/lib/Heading'
import TextLink from '@kiwicom/orbit-components/lib/TextLink'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import Text from '@kiwicom/orbit-components/lib/Text'
import React, { useEffect } from 'react'
import Gap from '../components/Gap'

function HomePage(props) {
  return (
    <Box maxWidth="500px">
      <Gap gap="24px" />
      <Stack>
        <Heading>VIRTII Configer App</Heading>
        <Text>
          This app was made for simple config files (such as texts /
          translations) setup.
        </Text>
        <Gap gap="24px" />
        <Heading type="title2">A dál už jen česky...</Heading>
        <Heading type="title3">Rychlý návod pro překlady / texty</Heading>
        <Text>
          Po kliku na Save se texty uloží na serveru, ale ještě nejsou dostupné
          v cílovém webu/aplikaci. Tam se zpravidla nahrávají jednou za den.
        </Text>
        <Text>
          Pokud to chcete urychlit a váš projekt to umožňuje, tak klikněte na
          některé z tlačítek v sekci Triggers, které pošle signál cílovému webu,
          aby si stáhl nové překlady. V některých případech je potřeba cílový
          web znovu zbuildovat (a nasadit), což může zabrat nějaký čas a proto
          buďte prosím trpěliví. Opakované klikání na tlačítko nic nezrychlí a
          jen zařadí do fronty další build, který se provede po skončení již
          stávajícího buildu.
        </Text>
        <Gap gap="24px" />

        <Heading type="title2">Feedback</Heading>
        <Text>
          Něco vám tu chybí, překáží a chtěli byste to jinak?
          <br />
          Napište na{' '}
          <TextLink href="mailto:petr@virtii.com">petr@virtii.com</TextLink>
        </Text>
      </Stack>
    </Box>
  )
}

export default HomePage
