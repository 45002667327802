import React, { useState, useEffect, useContext, createContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import useLocalStorageState from 'use-local-storage-state'
import useApiCall from './useApiCall'

const authContext = createContext({})

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext)

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useLocalStorageState('user', null)
  const [tokens, setTokens] = useLocalStorageState('tokens', null)

  const [signInCall] = useApiCall({ path: '/auth/sign-in', method: 'POST' })

  const signInMutation = useMutation((body) => signInCall(body), {
    onSuccess: ({ user, tokens }) => {
      console.log('user', user, tokens)
      setTokens(tokens)
      setUser(user)
    },
    onError: (error) => {
      console.error('login error', error)
    },
  })

  const signOut = async () => {
    setUser(null)
    setTokens(null)
    return user
  }

  // Return the user object and auth methods
  return {
    user,
    tokens,
    signInMutation,
    signOut,
    setUser,
  }
}

