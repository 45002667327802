import React, { Component, useContext, useEffect, useRef } from 'react'
import InputFieldKiwi from '@kiwicom/orbit-components/lib/InputField'

import { useFormikContext } from 'formik'

const InputField = ({
  placeholder,
  label,
  name,
  ...props
}) => {
  const { values, handleBlur, handleChange, errors } = useFormikContext()


  return (
    <div>
      <InputFieldKiwi
        {...props}
        label={label}
        error={errors[name]}
        value={values[name]}
        onBlur={handleBlur(name)}
        onChange={handleChange(name)}
        placeholder={placeholder}
      />
    </div>
  )
}

export default InputField
