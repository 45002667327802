import Button from '@kiwicom/orbit-components/lib/Button'
import LinkList from '@kiwicom/orbit-components/lib/LinkList'
import NavigationBar from '@kiwicom/orbit-components/lib/NavigationBar'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import Link from './Link'

function NavBar(props) {
  const { user, signOut } = useAuth()
  const history = useHistory()

  return (
    <NavigationBar>
      <Stack align="center" flex justify="between" spacing="none">
        <LinkList direction="row">
          <Link to="/" trackActive>
            Home
          </Link>
          <Link to="/projects" trackActive>
            Projects
          </Link>
        </LinkList>
        <Stack direction="row" justify="end" shrink spacing="XXSmall">
          {user && (
            <Link
              asButton
              type="white"
              to="/profile"
              // onClick={() => {
              //   history.push('/auth')
              //   signOut()
              // }}
            >
              Profile
            </Link>
          )}
          {!user && (
            <Link to="/auth" asButton>
              Login
            </Link>
          )}
        </Stack>
      </Stack>
    </NavigationBar>
  )
}

export default NavBar
