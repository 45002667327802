import Alert from '@kiwicom/orbit-components/lib/Alert'
import Box from '@kiwicom/orbit-components/lib/Box'
import Heading from '@kiwicom/orbit-components/lib/Heading'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import Text from '@kiwicom/orbit-components/lib/Text'
import TextareaKiwi from '@kiwicom/orbit-components/lib/Textarea'
import Separator from '@kiwicom/orbit-components/lib/Separator'
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox'
import ChoiceGroup from '@kiwicom/orbit-components/lib/ChoiceGroup'
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip'
import Grid from '@kiwicom/orbit-components/lib/utils/Grid'
import axios from 'axios'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Link, Prompt, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import PageLoader from '../components/PageLoader'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import Textarea from '../components/Textarea'
import Gap from '../components/Gap'
import Button from '@kiwicom/orbit-components/lib/Button'
import {
  useUpdateValuesMutation,
  useTriggerHookMutation,
} from '../hooks/useApiMutations'
import { useProject } from '../hooks/useProject'
import { useAuth } from '../hooks/useAuth'
import useBeforeUnload from '../hooks/useBeforeUnload'
import InputField from '@kiwicom/orbit-components/lib/InputField'

const PageWrapper = styled.div`
  height: 100%;
`
const KeyTag = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 100%;
  span {
    max-width: 100%;
    code {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      display: block;
      font-size: 12px;
      color: gray;
      /* max-width: 400px; */
    }
  }
`
const ElevatedWrapper = styled.div`
  padding: 16px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 0px rgb(37 42 49 / 16%),
    0px 1px 4px 0px rgb(37 42 49 / 12%);
  min-width: 220px;
  margin-bottom: 8px;

  ${({ isUpdated }) =>
    isUpdated &&
    css`
      /* border: 1px solid gray; */
    `}
`
const KeyValuesWrapper = styled.div`
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  flex: 1;
`

export function fullNormalizeForSearch(text = '') {
  return (
    text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // remove diacritics
      // .replace(/\.|:|,/g, ' ') // remove dots, colons, commas
      .replace(/:|-|_/g, ' ') // remove colons, dashes, underscores
      .replace(/,/g, '.') // replace commas with dots
      .replace(/\s\s+/g, ' ') // multiple spaces
      .trim()
  ) // trim spaces
}

function ProjectPage() {
  const { projectKey } = useParams()
  const [shownVersions, setShowLangs] = useState({})
  const [showKeys, setShowKeys] = useState(true)
  const [updates, setUpdates] = useState({})
  const [searchedText, setSearchedText] = useState('')
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const hasUpdates = Object.keys(updates).length > 0
  useBeforeUnload(hasUpdates)

  const {
    status,
    data: project,
    error,
    failureCount,
    queryKey,
    ...projectQuery
  } = useProject(projectKey, {
    onSuccess: (project) => {
      const versionKeys = project?.versions?.reduce(
        (acc, key) => ({
          ...acc,
          [key]: true,
        }),
        {}
      )

      setShowLangs(versionKeys)
    },
  })

  const updateValuesMutation = useUpdateValuesMutation(projectKey, {
    onSuccess: () => {
      setUpdates({})
      queryClient.invalidateQueries(queryKey)
    },
  })

  const triggerHookMutation = useTriggerHookMutation(projectKey)

  // console.log('project', project)

  if (status !== 'success') {
    return (
      <>
        {status === 'loading' && <PageLoader />}

        {status === 'error' && (
          <Alert icon title="Error loading project data" type="critical">
            {error.errors?.map?.((err) => err.message)}
          </Alert>
        )}
      </>
    )
  }

  const shownVersionsIndexes = Object.keys(shownVersions).reduce((acc, key) => {
    const index = project.versions.indexOf(key)
    acc[index] = shownVersions[key]
    return acc
  }, {})

  function searchFunc(item) {
    const normalizedSearchText = fullNormalizeForSearch(searchedText)

    return (
      item.values.some(
        (v) => fullNormalizeForSearch(v).indexOf(normalizedSearchText) !== -1
      ) || item.key.indexOf(normalizedSearchText) !== -1
    )
  }

  return (
    <PageWrapper>
      {status === 'success' && (
        <Stack direction="row" justify="stretch">
          <Prompt
            when={hasUpdates}
            message={(location) =>
              `Are you sure you want to discard all unsaved changes?`
            }
          />
          <div>
            <ElevatedWrapper>
              <Stack>
                <Heading>{project.name}</Heading>
                <Text>
                  All versions: {project.versions.join(', ')} <br />
                  Default version: {project.defaultVersion} <br />
                </Text>
                <Separator />

                <ChoiceGroup
                  label="Show versions"
                  onChange={(event) => {
                    const updateVersionIndex = event.target.name.substring(2)

                    setShowLangs({
                      ...shownVersions,
                      [updateVersionIndex]: event.target.checked,
                    })
                  }}
                >
                  {project.versions.map((version, index) => (
                    <Checkbox
                      key={version}
                      label={version}
                      name={`v-${version}`}
                      checked={shownVersions[version]}
                    />
                  ))}
                </ChoiceGroup>

                <Separator />

                <Checkbox
                  label="Show translation keys"
                  checked={showKeys}
                  onChange={() => setShowKeys(!showKeys)}
                />
                <Separator />
                <Button
                  onClick={() => {
                    updateValuesMutation.mutate({ updates })
                  }}
                  disabled={!hasUpdates}
                  loading={updateValuesMutation.isLoading}
                >
                  Save
                </Button>

                <Button
                  type="criticalSubtle"
                  onClick={() => {
                    if (
                      // eslint-disable-next-line no-restricted-globals
                      confirm(
                        'Are you sure you want to delete all unsaved changes?'
                      )
                    ) {
                      setUpdates({})
                      projectQuery.remove()
                    }
                    // queryClient.invalidateQueries(queryKey)
                  }}
                  disabled={!hasUpdates}
                  loading={updateValuesMutation.isLoading}
                >
                  Reset all changes
                </Button>
              </Stack>
            </ElevatedWrapper>
            <ElevatedWrapper>
              <Stack>
                <Heading type="title2">Triggers</Heading>
                {!project.hooksUrls?.length ? (
                  <Text>No triggers for this project.</Text>
                ) : (
                  <Stack>
                    {project.triggersDescription && (
                      <Box maxWidth="230px">
                        <Text>{project.triggersDescription}</Text>
                      </Box>
                    )}
                    {project.hooksUrls.map((hook, index) => (
                      <Button
                        key={index}
                        size="small"
                        onClick={() => {
                          if (hasUpdates) {
                            alert('Save changes first!')
                          } else {
                            triggerHookMutation.mutate(index)
                            alert(
                              'Hook triggered. Give it some time to see your updates live!'
                            )
                          }
                        }}
                      >
                        {project.hooksNames[index] || hook}
                      </Button>
                    ))}
                  </Stack>
                )}
              </Stack>
            </ElevatedWrapper>
            <ElevatedWrapper>
              <Stack>
                <Heading type="title2">Search</Heading>
                <InputField
                  placeholder="Type and press enter"
                  onKeyUp={(e) => {
                    if (e.key === 'Enter' || e.keyCode === 13) {
                      setSearchedText(e.target.value)
                    }
                    if (e.target.value === '') {
                      setSearchedText(e.target.value)
                    }
                  }}
                  onBlur={(e) => {
                    setSearchedText(e.target.value)
                  }}
                />
              </Stack>
            </ElevatedWrapper>
            <ElevatedWrapper>
              <Stack>
                <Heading type="title2">Stats</Heading>

                <Stack spacing="XSmall">
                  <Heading type="title3">Letters count</Heading>
                  {project.versions.map((version) => (
                    <Text key={version}>
                      {version}: {project.letters[version]} (
                      {Math.round(project.letters[version] / 18) / 100} pages)
                    </Text>
                  ))}
                </Stack>
                <Stack spacing="XSmall">
                  <Heading type="title3">Progress</Heading>
                  {project.versions.map((version) => (
                    <Text key={version}>
                      {version}: {project.progress[version].filled} /{' '}
                      {project.progress[version].total} (
                      {project.progress[version].total -
                        project.progress[version].filled}{' '}
                      left)
                    </Text>
                  ))}
                </Stack>
              </Stack>
            </ElevatedWrapper>
          </div>
          <KeyValuesWrapper>
            {project.keyValues.filter(searchFunc).map((kv) => {
              const transformedValues = kv.values.map((value, index) => {
                return {
                  index,
                  value,
                  shown: shownVersionsIndexes[index],
                  isDefault: project.defaultVersionIndex === index,
                  versionName: project.versions[index],
                }
              })

              return (
                <ElevatedWrapper key={kv.key} isUpdated={updates[kv.key]}>
                  {showKeys && (
                    <KeyTag>
                      <Tooltip
                        preferredPosition="top"
                        content={`Original translation key: ${kv.key}`}
                      >
                        <code>{kv.key}</code>
                      </Tooltip>
                    </KeyTag>
                  )}
                  <Gap gap="8px" />
                  <Stack>
                    {transformedValues.map(
                      (valueContainer) =>
                        valueContainer.shown && (
                          <div key={valueContainer.index}>
                            <Textarea
                              disabled={
                                updateValuesMutation.isLoading ||
                                (user.disabledVersions || []).includes(
                                  valueContainer.versionName
                                )
                              }
                              isUpdated={
                                typeof updates[kv.key]?.[
                                  valueContainer.index
                                ] !== 'undefined'
                              }
                              label={valueContainer.versionName}
                              defaultValue={valueContainer.value}
                              fullHeight
                              onBlur={(e) => {
                                if (e.target.value !== valueContainer.value) {
                                  setUpdates({
                                    ...updates,
                                    [kv.key]: {
                                      ...updates[kv.key],
                                      [valueContainer.index]: e.target.value,
                                    },
                                  })
                                }
                              }}
                            />
                          </div>
                        )
                    )}
                  </Stack>
                </ElevatedWrapper>
              )
            })}
          </KeyValuesWrapper>
        </Stack>
      )}
    </PageWrapper>
  )
}

export default ProjectPage
