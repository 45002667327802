import Button from '@kiwicom/orbit-components/lib/Button'
import Card from '@kiwicom/orbit-components/lib/Card'
import Heading from '@kiwicom/orbit-components/lib/Heading'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import Text from '@kiwicom/orbit-components/lib/Text'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { useAuth } from '../hooks/useAuth'
import InputField from '../components/fields/InputField'
import * as Yup from 'yup'
import { schemaChangePassword } from '../common/validations/users'
import Box from '@kiwicom/orbit-components/lib/Box'
import Gap from '../components/Gap'
import { useUpdatePasswordMutation } from '../hooks/useUser'
import Alert from '@kiwicom/orbit-components/lib/Alert'

function ProfilePage() {
  const { user, signOut } = useAuth()
  const history = useHistory()
  const updatePasswordMutation = useUpdatePasswordMutation()

  console.log('user', user)
  return (
    <Stack align="center" direction="column">
      <Gap gap="24px" />

      <Box maxWidth="400px" width="full">
        <Heading>Your profile settings</Heading>
        <Text>
          Logged as: {user.name.first} {user.name.last} ({user.email})
        </Text>

        <Gap gap="48px" />

        <Formik
          initialValues={{ oldPassword: '', password: '' }}
          onSubmit={(data, actions) => {
            updatePasswordMutation.mutate(data)
            actions.setSubmitting(false)
          }}
          validationSchema={schemaChangePassword}
        >
          {({ isSubmitting, handleSubmit, submitForm, ...rest }) => (
            <form onSubmit={handleSubmit}>
              <Stack align="flex-start" direction="column" spacing="large">
                <Heading type="title2">Change password</Heading>
                <InputField
                  name="oldPassword"
                  label="Old Password"
                  type="password"
                />
                <InputField
                  name="password"
                  label="New Password"
                  type="password"
                />

                <Button
                  type="primary"
                  onClick={submitForm}
                  // disabled={isSubmitting}
                  loading={updatePasswordMutation.isLoading}
                >
                  Change Password
                </Button>
                {updatePasswordMutation.error && (
                  <Alert icon title="Update error" type="critical">
                    {updatePasswordMutation.error?.errors?.map((error) => (
                      <>{error.message} </>
                    ))}
                  </Alert>
                )}
                {updatePasswordMutation.isSuccess && (
                  <Alert icon title="Success" type="success">
                    Password successfully changed!
                  </Alert>
                )}
              </Stack>
            </form>
          )}
        </Formik>

        <Gap gap="48px" />
        
        <Stack>
          <Heading type="title2">Or sign out</Heading>
          <Button
            type="white"
            onClick={() => {
              signOut()
              history.push('/auth')
            }}
          >
            Sign out
          </Button>
        </Stack>
      </Box>
    </Stack>
  )
}

export default ProfilePage
