import React, { useEffect } from 'react'
import { useRefreshMe } from '../hooks/useUser'

function DataUpdater(props) {
  const refreshMe = useRefreshMe()
  // useEffect(() => {
  //   refreshMe()
  // }, [])

  return null
}

export default DataUpdater
