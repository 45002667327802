import React from 'react'
import { useMutation, useQuery } from 'react-query'
import useApiCall from './useApiCall'

export const decorateProject = (project) => {
  if (!project) {
    return null
  }

  const versionsToIndexes = project.versions.reduce((acc, curr, index) => {
    return {
      ...acc,
      [curr]: index,
    }
  }, {})

  const defaultVersionIndex = project.versions.indexOf(project.defaultVersion)


  const letters = {}
  project.versions.forEach((version) => {
    letters[version] = project.keyValues.reduce((acc, curr) => {
      return acc + curr.values[versionsToIndexes[version]]?.length
    }, 0)
  })

  const progress = {}
  project.versions.forEach((version) => {
    progress[version] = {
      total: project.keyValues.length,
      filled: project.keyValues.reduce((acc, curr) => {
        return acc + (curr.values[versionsToIndexes[version]] ? 1 : 0)
      }, 0),
    }
  })

  return {
    ...project,
    defaultVersionIndex,
    letters,
    progress,
  }
}

export const useProject = (key, queryOptions) => {
  const [fetchProject] = useApiCall({ path: 'projects/' + key })
  const queryKey = 'fetchOneProject'

  const query = useQuery(
    queryKey,
    async () => {
      const project = await fetchProject()
      return project
    },
    queryOptions
  )

  return {
    ...query,
    data: decorateProject(query.data),
    queryKey,
  }
}
