import React from 'react'
import { useMutation, useQuery } from 'react-query'
import useApiCall from './useApiCall'

export const useProjects = () => {
  const [fetchProjects] = useApiCall({ path: 'projects' })
  return useQuery('projects', async () => {
    const projects = await fetchProjects()
    return projects
  })
}