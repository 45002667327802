import Stack from '@kiwicom/orbit-components/lib/Stack'
import Text from '@kiwicom/orbit-components/lib/Text'
import React from 'react'
import styled, { css } from 'styled-components'

const StyledTextarea = styled.textarea`
  appearance: none;
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  padding: 12px;
  box-shadow: inset 0 0 0 1px #bac7d5;
  background-color: #ffffff;
  color: #252a31;
  font-size: 14px;
  line-height: 20px;
  cursor: text;
  font-family: 'Roboto', sans-serif;
  resize: vertical;
  transition: box-shadow 0.15s ease-in-out;
  min-height: 44px;
  border-radius: 6px;
  flex: 1;
  border: 1px solid transparent;
  overflow: auto;

  ${({ isUpdated }) =>
    isUpdated &&
    css`
      border: 2px solid #ff9800;
      box-shadow: none;
    `}
`

function Textarea({ label, ...props }) {
  return (
    <div>
      <Text>{label}</Text>
      <StyledTextarea placeholder="To fill in" {...props} />
    </div>
  )
}

export default Textarea
