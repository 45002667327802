import { Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import InputField from '../components/fields/InputField'
import { useAuth } from '../hooks/useAuth'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import Button from '@kiwicom/orbit-components/lib/Button'
import Alert from '@kiwicom/orbit-components/lib/Alert'
import Heading from '@kiwicom/orbit-components/lib/Heading'
import Grid from '@kiwicom/orbit-components/lib/utils/Grid'
import Box from '@kiwicom/orbit-components/lib/Box'
import Text from '@kiwicom/orbit-components/lib/Text'
import Tile from '@kiwicom/orbit-components/lib/Tile'
import Gap from '../components/Gap'

function AuthPage(props) {
  const history = useHistory()
  const location = useLocation()
  const { user, signInMutation, signOut } = useAuth()

  const { from } = location.state || { from: { pathname: '/' } }

  const login = async ({ email, password }) => {
    signInMutation.mutate(
      {
        email,
        password,
      },
      {
        onSuccess: () => {
          history.replace(from)
        },
      }
    )
  }

  // console.log('signInMutation', signInMutation)

  return (
    <>
      <Stack justify="center" align="center" direction="column">
        {!user ? (
          <>
            {location?.state?.from && (
              <Alert icon title="Safety first!" type="warning">
                You must log in to view the page at {from.pathname}
              </Alert>
            )}

            {signInMutation.error && (
              <Alert icon title="Login error" type="critical">
                {signInMutation.error?.errors?.map((error) => (
                  <>{error.message} </>
                ))}
              </Alert>
            )}
            <Gap gap="20px" />
            <Box maxWidth="350px" width="full">
              <Tile>
                <Stack spacing="large">
                  <Heading>Sign in</Heading>
                  <Formik
                    initialValues={{ email: '', password: '' }}
                    onSubmit={(values, actions) => {
                      login(values)
                      actions.setSubmitting(false)
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string()
                        .email('Není emailová adresa')
                        .required('Povinné'),
                      password: Yup.string().required('Povinné'),
                    })}
                  >
                    {({ isSubmitting, handleSubmit, submitForm, ...rest }) => (
                      <form onSubmit={handleSubmit}>
                        <Stack
                          align="flex-start"
                          direction="column"
                          spacing="large"
                        >
                          <InputField
                            name="email"
                            label="E-mail"
                            type="email"
                          />
                          <InputField
                            name="password"
                            label="Password"
                            type="password"
                          />
                          {(() => {
                            console.log('rest', rest)
                          })()}
                          <Button
                            type="primary"
                            onClick={submitForm}
                            // disabled={isSubmitting}
                            loading={signInMutation.isLoading}
                          >
                            Log in
                          </Button>
                        </Stack>
                      </form>
                    )}
                  </Formik>
                </Stack>
              </Tile>
            </Box>
          </>
        ) : (
          <Text>You are already signed</Text>
        )}
      </Stack>
    </>
  )
}

export default AuthPage
