import * as yup from 'yup'

// export const validateCreate = (data) => {
//   const validator = new Validation(data)
//   validator.areRequired(['firstName', 'lastName', 'password', 'email', 'role'])
//   validator.isEmail('email')
//   return validator.validate()
// }

// export const validateUpdate = (data) => {
//   const validator = new Validation(data)
//   validator.isRequired('email', '')
//   if (data.password) {
//     validator.isRequired('passwordConfirm', messages.passwordConfirmRequired)
//     validator.areEqual('password', 'passwordConfirm', messages.passwordsMismatch)
//   }
//   return validator.validate()
// }

export const schemaChangePassword = yup.object({
  oldPassword: yup.string().required(),
  password: yup.string().required(),
})

export const validateChangePassword = async (data) => {
  const schema = schemaChangePassword

  return schema.validate(data)
}
