import Box from '@kiwicom/orbit-components/lib/Box'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`

function PageLoader() {
  return (
    <Wrapper>
      <Loading
        loading
        text="Please wait, content of the page is loading..."
        type="pageLoader"
      />
    </Wrapper>
  )
}

export default PageLoader
