import React from 'react'
import { useMutation, useQuery } from 'react-query'
import useApiCall from './useApiCall'

export const useUpdateValuesMutation = (key, callbacks) => {
  const [apiCall] = useApiCall({
    path: '/projects/' + key + '/updateValues',
    method: 'POST',
  })

  return useMutation((body) => apiCall(body), callbacks)
}

export const useTriggerHookMutation = (key, callbacks) => {
  const [apiCall] = useApiCall({
    method: 'POST',
  })

  return useMutation(
    (triggerIndex) =>
      apiCall(null, '/projects/' + key + '/trigger/' + triggerIndex),
    callbacks
  )
}
