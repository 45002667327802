import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useLocation,
  Redirect,
  useHistory,
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import styled, {
  createGlobalStyle,
  ThemeProvider,
  css,
} from 'styled-components'
import styledNormalize from 'styled-normalize'
import NavigationBar from '@kiwicom/orbit-components/lib/NavigationBar'
import LinkList from '@kiwicom/orbit-components/lib/LinkList'
import TextLink from '@kiwicom/orbit-components/lib/TextLink'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink'

// import { theme } from '../lib/styles'
import superFormReset from './lib/superFormReset'

import { ProvideAuth, useAuth } from './hooks/useAuth'
import HomePage from './pages/HomePage'
import AuthPage from './pages/AuthPage'
import ProjectPage from './pages/ProjectPage'
import ProjectsPage from './pages/ProjectsPage'
import ProfilePage from './pages/ProfilePage'
import Link from './components/Link'
import Grid from '@kiwicom/orbit-components/lib/utils/Grid'
import Box from '@kiwicom/orbit-components/lib/Box'
import NavBar from './components/NavBar'
import DataUpdater from './components/DataUpdater'

const routes = [
  {
    path: '/',
    exact: true,
    component: HomePage,
  },
  {
    path: '/projects/:projectKey',
    component: ProjectPage,
  },
  {
    path: '/projects',
    component: ProjectsPage,
  },
  {
    path: '/profile',
    component: ProfilePage,
  },
  {
    path: '/auth',
    component: AuthPage,
    isPublic: true,
  },
]

const GlobalStyle = createGlobalStyle`${() => css`
  ${superFormReset}
  ${styledNormalize}

  html {
    /* scroll-behavior: smooth; */
  }

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #f3f3f3;
  }
  * {
    box-sizing: border-box;
  }
  #root {
    height: 100%;
  }
`}`

const queryClient = new QueryClient()

const LayoutWrapper = styled.div`
  padding: 80px 16px 16px;
`

function App() {
  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <ProvideAuth>
          <DataUpdater />
          <Router>
            <LayoutWrapper>
              <Box />

              <NavBar />

              <Switch>
                {routes.map((route, index) => {
                  const RouteComponent = route.isPublic ? Route : PrivateRoute
                  return (
                    <RouteComponent
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      children={<route.component />}
                    />
                  )
                })}

                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </LayoutWrapper>
          </Router>
        </ProvideAuth>
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </>
  )
}

export default App

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

function NoMatch() {
  const location = useLocation()

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  )
}
