import { useEffect } from 'react'

function useBeforeUnload(enabled) {
  useEffect(() => {
    function beforeUnload(e) {
      if (!enabled) {
        return undefined
      }

      var confirmationMessage =
        'It looks like you have been editing something. ' +
        'If you leave before saving, your changes will be lost.'

      ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
      return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
    }

    window.addEventListener('beforeunload', beforeUnload)

    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [enabled])
}


export default useBeforeUnload