import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useProjects } from '../hooks/useApiQueries'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import Tile from '@kiwicom/orbit-components/lib/Tile'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import Heading from '@kiwicom/orbit-components/lib/Heading'
import Grid from '@kiwicom/orbit-components/lib/utils/Grid'
import Gap from '../components/Gap'
import Alert from '@kiwicom/orbit-components/lib/Alert'
import PageLoader from '../components/PageLoader'
import { useAuth } from '../hooks/useAuth'

function ProjectsPage() {
  const { status, data, error, failureCount } = useProjects()
  const history = useHistory()
  const { user } = useAuth()

  return (
    <div>
      {status === 'loading' && <PageLoader />}

      {status === 'error' && (
        <Alert icon title="Error loading project data" type="critical">
          {error.errors?.map?.((err) => err.message)}
        </Alert>
      )}

      {status === 'success' && (
        <>
          <Heading>Projects</Heading>
          <Gap gap="24px" />
          <Grid columns="1fr 1fr" gap="24px">
            {data?.map((project) => {
              return (
                <Tile
                  title={project.name}
                  key={project.projectKey}
                  onClick={() => {
                    history.push(`/projects/${project.projectKey}`)
                  }}
                >
                  All versions: {project.versions.join(', ')} <br />
                  Your versions:{' '}
                  {project.versions
                    .filter((v) => !user.disabledVersions?.includes(v))
                    .join(', ')}{' '}
                  <br />
                </Tile>
              )
            })}
          </Grid>
        </>
      )}
    </div>
  )
}

export default ProjectsPage
