import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import TextLink from '@kiwicom/orbit-components/lib/TextLink'
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink'

function Link({
  type = 'secondary',
  children,
  to,
  onClick,
  asButton,
  trackActive,
  ...rest
}) {
  const history = useHistory()
  const location = useLocation()

  const Component = asButton ? ButtonLink : TextLink

  const isActive = trackActive && to && location.pathname === to

  return (
    <Component
      {...rest}
      onClick={(e) => {
        if (to) {
          history.push(to)
        }
        onClick?.(e)
      }}
      type={isActive ? 'primary' : type}
    >
      {children}
    </Component>
  )
}

export default Link
