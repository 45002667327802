import { useMutation, useQuery, useQueryClient } from 'react-query'
import useApiCall from './useApiCall'
import { useAuth } from './useAuth'

export const useMe = (callbacks) => {
  const [fetchIt] = useApiCall({ path: 'users/me' })
  const queryKey = 'fetchMe'
  return {
    ...useQuery(
      queryKey,
      async () => {
        const data = await fetchIt()
        return data
      },
      callbacks
    ),
    queryKey,
  }
}

export const useRefreshMe = () => {
  const { setUser } = useAuth()
  const queryClient = useQueryClient()
  const { remove, queryKey } = useMe({
    onSuccess: ({ me }) => {
      setUser(me)
    },
  })

  function refreshMe() {
    queryClient.invalidateQueries(queryKey)
  }

  return refreshMe
}

export const useUpdatePasswordMutation = (callbacks) => {
  const [apiCall] = useApiCall({
    path: '/users/me/password',
    method: 'PUT',
  })

  return useMutation((body) => apiCall(body), callbacks)
}
